<template>
  <div class="hello">
    <div class="hellotitle" v-animation:home="'a-fadeinT'">
      合作伙伴的信赖是对我们最大的支持
    </div>
    <div class="hellowbox" v-animation:home="'a-fadeinT'">
      <div class="boxleft">
        <div class="left1">联系我们</div>
        <div class="left2">
          <el-image
            class="leftico"
            :src="require('../assets/座机.png')"
            fit="cover"
          ></el-image>
          <div class="leftico2">
            <div class="ico1">成为合伙人请拨打招商热线：</div>
            <div class="ico2">4008-515-258</div>
          </div>
        </div>
        <!-- <div class="left2">
          <el-image
            class="leftico"
            :src="require('../assets/电话.png')"
            fit="cover"
          ></el-image>
          <div class="leftico2">
            <div class="ico1">投诉建议请拨打客服热线：</div>
            <div class="ico2">400-8888-888</div>
          </div>
        </div> -->
      </div>
      <div class="boxright">
        <div class="right1">
          <div class="lt">申请合作</div>
          <div class="lt2">智能时代·商机一触即发</div>
        </div>
        <div class="right2">
          <input
            v-model="form.name"
            class="inputbox"
            placeholder="请输入您的姓名"
          />
        </div>
        <div class="right2">
          <input
            v-model="form.mobile"
            class="inputbox"
            placeholder="请输入您的电话"
          />
        </div>
        <div class="right3">
          <div class="change" @click="changetype(true)">
            <div class="dian">
              <div class="indian" v-show="type"></div>
            </div>
            <div class="zhi">申请代理</div>
          </div>
          <div class="change" @click="changetype(false)">
            <div class="dian">
              <div class="indian" v-show="!type"></div>
            </div>
            <div class="zhi">商铺入住</div>
          </div>
        </div>
        <div class="right4 hvr-bounce-to-right-1" @click="submit">立即提交</div>
      </div>
    </div>
    <div class="bottom">
      <div class="left-bottom">
        <el-image
          class="logoimg"
          :src="require('../assets/logo_new.png')"
          fit="contain"
        ></el-image>
      </div>
      <div class="right-bottom">
        <div class="adress">杭州晓荷网络科技有限公司</div>
        <div class="adress">
          公司地址:浙江省杭州市滨江区泰安路199号浙江农资大厦21层
        </div>
        <div class="adress">
          版权所有:© 2020 杭州晓荷科技有限公司. All Rights Reserved.
          <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="" style="float:left;"/><p style="font-size:15px;float:left;height:20px;line-height:28px;margin: 0px 0px 0px 5px; color:#939393;">浙ICP备2020042418号-1</p></a>
          •
          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010802012234" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="备案图标.png" style="float:left;"/><p style="font-size:15px;float:left;height:20px;line-height:28px;margin: 0px 0px 0px 5px; color:#939393;">浙公网安备 33010802012234号</p></a>
        </div>
        <div style="width:300px;margin:0 auto; padding:20px 0;">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { joinApply } from "@/api/api_list";
export default {
  name: "HelloWorld",
  data() {
    return {
      type: true,
      form: {},
    };
  },
  methods: {
    changetype(item) {
      this.type = item;
    },
    submit() {
      this.type ? (this.form.joinType = 1) : (this.form.joinType = 2);
      var telStr =
        /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/;
      if (!this.form.name) {
        alert("请您输入姓名");
        return;
      }
      if (!telStr.test(this.form.mobile)) {
        alert("手机号码输入不规范");
        return;
      }

      var param = {...this.form}
      param.joinChannel = 2;

      joinApply(param).then((res) => {
        console.log(res);
        if (res.code == 200) {
          alert("添加成功！");
          this.form = {};
        }
      });
    },
    goban() {
      window.open("https://beian.miit.gov.cn/#/Integrated/index", "_blank");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.hello {
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  color: black;
  .hellotitle {
    width: 100%;
    height: 40px;
    text-align: center;
    font-size: 28px;

    margin-top: 100px;
    font-weight: 900;
  }
  .hellowbox {
    width: 80%;
    height: 50%;
    text-align: center;
    font-size: 28px;
    font-weight: 900;
    margin: 0 auto;
    margin-top: 30px;
    display: flex;
    .boxleft {
      width: 50%;
      height: 100%;
      .left1 {
        width: 100%;
        height: 30%;
        display: flex;
        align-items: center;
        font-size: 18px;
        font-weight: bold;
        font-weight: 100 !important;
      }
      .left2 {
        width: 100%;
        height: 30%;
        display: flex;
        align-items: center;
        font-size: 28px;
        font-weight: bold;
        margin-top: 20px;
        .leftico {
          margin-left: 5%;
          width: 60px;
          height: 60px;
        }
        .leftico2 {
          font-weight: 100 !important;
          flex: 1;
          /* width: 100%; */
          height: 100%;
          /* background: yellow; */
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          padding-left: 5%;
          .ico1 {
            margin-bottom: 1%;
            font-size: 16px;
            font-weight: bold;
            word-break: break-all;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
          }
          .ico2 {
            word-break: break-all;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            color: rgb(153, 153, 153);
          }
        }
      }
    }
    .boxright {
      width: 50%;
      height: 100%;
      /* background:red; */
      .right1 {
        width: 100%;
        height: 30%;
        display: flex;
        align-items: center;
        font-size: 28px;
        font-weight: bold;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        font-weight: 100 !important;
        .lt {
          font-size: 20px;
          font-weight: bold;
        }
        .lt2 {
          font-size: 14px;
          margin-top: 10px;
          color: rgb(153, 153, 153);
          font-weight: bold;
        }
      }
      .right2 {
        padding-bottom: 2%;
        box-sizing: border-box;
        text-align: initial;
        width: 100%;
        height: 15%;
        /* background: yellow; */
        .inputbox {
          border: none;
          width: 100%;
          height: 100%;
          font-size: 12px;
          text-indent: 10px;
          outline: none;
        }
      }
      .right3 {
        margin-top: 1%;
        width: 100%;
        height: 10%;
        /* background: khaki; */
        display: flex;
        align-items: center;
        .change {
          width: 100px;
          display: flex;
          align-items: center;
          .dian {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            border: 1px solid black;
            display: flex;
            justify-content: center;
            align-items: center;
            .indian {
              width: 7px;
              height: 7px;
              border-radius: 50%;
              background: black;
            }
          }
          .zhi {
            margin-left: 4px;
            font-size: 12px;
          }
        }
      }
      .right4 {
        width: 100%;
        height: 12%;
        margin-top: 10px;
        border: 1px solid #999;
        font-size: 18px;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        // background: black;
        border-radius: 5px;
        outline: none;
        overflow: hidden;
        cursor: pointer;
        transform: scale(1);
      }
      .right4:hover {
        border-color: transparent;
        overflow: hidden;
      }
    }
  }
  .bottom {
    width: 80%;
    flex: 1;
    // background: chartreuse;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    .left-bottom {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 20%;
      height: 100%;
      .logoimg {
        width: 50%;
        height: 50%;
      }
    }
    .right-bottom {
      width: 80%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      margin-bottom: 3%;
      .adress {
        display: inline-block;
        font-size: 11px;
        color: #999;
      }
    }
  }
}

/* Bounce To Right */
.hvr-bounce-to-right-1 {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  overflow: hidden;
}
.hvr-bounce-to-right-1:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fdd947;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50%;
  transform-origin: 0 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  overflow: hidden;
}
.hvr-bounce-to-right-1:hover,
.hvr-bounce-to-right-1:focus,
.hvr-bounce-to-right-1:active {
  color: white;
  border-color: #fdd947;
  overflow: hidden;
}
.hvr-bounce-to-right-1:hover:before,
.hvr-bounce-to-right-1:focus:before,
.hvr-bounce-to-right-1:active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  overflow: hidden;
}
</style>
